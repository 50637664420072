<template>
    <div class="order-detail__body">
        <header class="order-detail__body_row">
            <p class="orders-details-tabs__tab-description">
                <InformationOutline />
                Here is the list of all support tickets open with regards to this project.
                You can create a new support ticket directly from this page.
            </p>
        </header>

        <div class="order-detail__body_row">
            <button
                class="btn-base btn-main"
                @click="scrollToSupportForm()"
            >
                Create a support ticket
            </button>

            <template
                v-for="(ticket, index) in order.tickets"
                class="tickets_card"
            >
                <support-card
                    :key="index"
                    :ticket="ticket"
                    :index="index"
                    @markAsRead="markAsRead"
                />
            </template>
        </div>
        <div class="order-detail__body_row">
            <div
                v-if="showCreateTicketForm"
                id="create-ticket"
                class="create-support-ticket orders-details-tabs__create"
            >
                <support-form
                    :order-id="order.orderid"
                    @supportFormCreated="onSupportFormCreated"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { scroller } from 'vue-scrollto/src/scrollTo'

import InformationOutline from 'mdi-vue/InformationOutline';
import SupportForm from '@/components/support/SupportTicketForm.vue'
import SupportCard from '@/components/account/Cards/SupportCard/SupportCard';

export default {
    components: {
        InformationOutline,
        SupportForm,
        SupportCard
    },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showCreateTicketForm: false
        }
    },
    methods: {
        scrollToSupportForm() {
            this.showCreateTicketForm = !this.showCreateTicketForm
            setTimeout(() => {
                const supportForm = document.querySelector('#create-ticket')
                if (supportForm) {
                    const ScrollTo = scroller();
                    ScrollTo(supportForm, {
                        duration: 600,
                        offset: -85
                    });
                }
            }, 300);
        },
        async onSupportFormCreated(payload) {
            this.showCreateTicketForm = false
            this.$emit('supportFormCreated', payload)
        },
        markAsRead(payload) {
            this.$emit('supporMarkAsRead', payload)
        }
    }
}
</script>

<style lang="scss">
.help-center {
    .tickets {
        padding: 0;
        margin-top: 30px;
    }

    .orders-details-tabs__support-tickets {
        padding: 30px 25px 10px;
        padding-top: 0;
    }

    .support-form {
        margin: 0;
        box-shadow: none;
    }
}
</style>
